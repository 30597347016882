




























import { defineComponent, PropType } from '@vue/composition-api';
import { ICardLink } from '@/types';

export default defineComponent({
  props: {
    icon: {
      type: String as PropType<ICardLink['icon']>,
      default: '',
    },
    title: {
      type: String as PropType<ICardLink['title']>,
      required: true,
    },
    description: {
      type: String as PropType<ICardLink['description']>,
      default: '',
    },
    to: {
      type: String as PropType<ICardLink['to']>,
      required: true,
    },
  },
});
