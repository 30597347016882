





















import { defineComponent } from '@vue/composition-api';
import CardLink from '@/components/CardLink.vue';
import { getElementId } from '@/utils';
import { ICardLink } from '@/types';

export default defineComponent({
  components: {
    CardLink,
  },

  setup() {
    const linkList: (ICardLink & { id: string })[] = [
      {
        id: 'template',
        title: 'Templates',
        description: 'Manage or Customize Work Order Templates.',
        to: '/templates',
        icon: 'mdi-file-document-multiple',
      },
      {
        id: 'grid-search',
        title: 'Grid Search',
        description: 'View grid search settings for your templates.',
        to: '/grid-search-settings',
        icon: 'mdi-cog',
      },
      {
        id: 'lists-config',
        title: 'Lists Configuration',
        description: 'View and manage reference lists and table lookups.',
        to: '/lists-config',
        icon: 'mdi-view-list',
      },
      {
        id: 'template-mappings',
        title: 'Template Mappings',
        description:
            'Configure data mapping between Work Order and Job templates.',
        to: '/template-mappings',
        icon: 'mdi-arrow-decision',
      },
      {
        id: 'inbound-mappings',
        title: 'Inbound Mappings',
        description: 'Map your inbound labels for your templates.',
        to: '/inbound-mappings',
        icon: 'mdi-email-receive-outline',
      },
      {
        id: 'outbound-mappings',
        title: 'Outbound Mappings',
        description: 'Create the outbound messages for your templates.',
        to: '/outbound-mappings',
        icon: 'mdi-email-send-outline',
      },
      {
        id: 'message-logs',
        title: 'Message Log',
        description: 'View messages sent for your templates.',
        to: '/message-logs',
        icon: 'mdi-email-multiple-outline',
      },
      {
        id: 'message-templates',
        title: 'Message Templates',
        description: 'Create E-mail and SMS based message templates.',
        to: '/message-templates',
        icon: 'mdi-application-edit-outline',
      },
    ];

    return {
      linkList, getElementId,
    };
  },
});
